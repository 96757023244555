$quote-width: 26px;
$quote-height: 28px;

.tab-pane {
    span.quote {
        padding-top: 4.8rem;

            &::before {
            content: '';
            position: absolute;
            background: url('//img.veeam.com/careers/global/icons/quotes.png');
            display: block;
            width: $quote-width;
            height: $quote-height;
            top: 0;
        }
    }
}
