$veeam-color-border-focus: $veeam-color-sapphire;


.navbar-nav {
  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  & + .navbar-nav {
    margin-left: $navbar-padding-x * 3;
  }

  font-weight: bold;


  .nav-link {
    @include media-breakpoint-up(lg) {
      padding: $nav-link-padding-y * 1.5 0;

      &:not(:last-child):not(:first-child) {
        margin-right: $nav-link-padding-x + 1rem;
      }
    }

    @include media-breakpoint-down(md) {

      display: block;

      & > .btn-primary {
        background: transparent;
        border: 0;
        color: inherit;
        text-align: left;
        padding-left: 0;
      }
    }

    padding: 0;
    text-transform: uppercase;
    color: $veeam-color-font;

    & > span {
      font-size: $font-size-base;
    }

    &:hover > span,
    &:focus > span {
      position: relative;

      &::after {
        bottom: 0;
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }

    &:hover > span::after {
      background-color: $veeam-color-light-green-hover;
    }

    &:focus > span::after {
      background-color: $veeam-color-light-green-pressed;
    }
  }
}

.navbar-toggler-icon {
  display: none;
}


.navbar-light {

  & .navbar-toggle {
    border-color: transparent;
  }

  & .navbar-toggler-icon {
    background: none;
  }

  & .navbar-nav .nav-link {
    display: flex;
    color: $veeam-color-font;
  }
}

.navbar-toggler {
  align-items: center;
  display: flex;
  width: $menu-nav-width;
  height: $menu-nav-height;
  border-radius: 50%;
  position: relative;
  background-color: $veeam-color-main;
  border: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(#{$menu-nav-height / 2} - #{$icon-sprites-height / 2});
    left: calc(#{$menu-nav-width / 2} - #{$icon-sprites-width / 2});
    width: $icon-sprites-width;
    height: $icon-sprites-height;
    background: url('//img.veeam.com/careers/global/icons/menu-opened.png');
  }


  &.collapsed::before {
    background: url('//img.veeam.com/careers/global/icons/menu-closed.png') no-repeat;
  }
}

.navbar-toggler:focus {
  outline: none;
  border-color: transparent;
}

.navbar-collapse {
  &.navbar-collapse-menu.collapse,
  &.navbar-collapse-menu {
    position: fixed;
    background: $veeam-color-control-bg;
    top: $mobile-menu-height;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $nav-link-padding-y * 1.9 0;
    color: $veeam-color-font;

    .container {
      display: block;
    }
    .dropdown-menu.show {
      overflow-y: auto;
    }
  }

  .navbar-nav {
    @include media-breakpoint-up(lg) {
      color: $veeam-color-placeholder;
    }

    .nav-link {
      padding: $nav-link-padding-y * 1.5 0;
    }

    color: $veeam-color-border-focus;
  }
}

.navbar {
  @include media-breakpoint-up(lg) {
    /*padding: $navbar-padding-y / 2 0;*/
    padding: 0;
  }

  padding: $navbar-padding-y - .8 0;
}

.navbar-close {
  display: none;
}
