// sass-lint:disable no-duplicate-properties
@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Thin-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Thin-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Thin-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-ThinItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-ThinItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-ThinItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Hairline-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Hairline-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Hairline-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Hairline-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-HairlineItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-HairlineItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-HairlineItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-HairlineItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Light-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Light-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Light-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-LightItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-LightItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-LightItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Regular-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Regular-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Regular-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-RegularItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-RegularItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-RegularItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Medium-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Medium-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Medium-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-MediumItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-MediumItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-MediumItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Semibold-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Semibold-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Semibold-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-SemiboldItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-SemiboldItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-SemiboldItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Bold-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Bold-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Bold-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-BoldItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-BoldItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-BoldItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Black-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Black-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Black-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-BlackItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-BlackItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-BlackItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-Ultra-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-Ultra-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-Ultra-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-Ultra-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Sans Cy Web";
  src: url($font-path-s3 + "GuardianSansCy-UltraItalic-Web.eot");
  src: url($font-path-s3 + "GuardianSansCy-UltraItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianSansCy-UltraItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianSansCy-UltraItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Medium-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Medium-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Medium-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Bold-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Bold-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Bold-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Black-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Black-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Black-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Regular-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Medium-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Medium-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Medium-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Bold-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Bold-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Bold-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-Black-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-Black-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-Black-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Guardian Text Sans Cy Web";
  src: url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.eot");
  src: url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.eot?#iefix") format("embedded-opentype"),
  url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.woff2") format("woff2"),
  url($font-path-s3 + "GuardianTextSansCy-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
