$theme-colors:(
        "primary":    $veeam-color-main,
        "secondary":  $veeam-color-light-green,
        "green":      $veeam-color-green,
        "success":    $veeam-color-main,
        "success-light":   $veeam-color-light-green-banner,
        "highlight":   $veeam-color-highlight,
        "danger":     $veeam-color-sapphire,
        "danger-light":     $veeam-color-sapphire-light,
        "info":       $veeam-color-control-bg,
        "warning":    $veeam-color-warning,
        "blue":       $veeam-color-blue,
        "light":      $veeam-color-body,
        "dark":       $veeam-color-footer-bg,
        "muted":      $veeam-color-disabled,
        "blockquote":  $veeam-color-font,
        "placeholder":   $veeam-color-placeholder,
        "placeholder-pressed":   $veeam-color-placeholder-pressed,
        "font":   $veeam-color-font
);

$font-path-s3: '/fonts/';
$font-family-opensans: (
        'Open Sans',
        'Droid Sans',
        'Helvetica Neue',
        Helvetica, Arial,
        sans-serif
);
$font-family-guardian: (
        'Guardian Sans Cy Web',
        sans-serif
);
$font-family-guardian-text: (
        'Guardian Text Sans Cy Web',
        sans-serif
);

$font-family-guardian-header: (
        'Guardian Sans Cy',
        sans-serif
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 740px,
        lg: 960px,
        xl: 1230px
);

$sizes: map-merge(
        (
                25: 25%,
                35: 35%,
                50: 50%,
                65: 65%,
                80: 80%,
                100: 100%,
                auto: auto
        ),
        $sizes
);

$spacers: map-merge(
        (
                0: 0,
                1: ($spacer * .25),
                2: ($spacer * .5),
                3: $spacer,
                4: ($spacer * 2.4),
                5: ($spacer * 3)
        ),
        $spacers
);

$body-color: $veeam-color-font;
$btn-font-size: 1.6rem;
$font-size-base: 1.6rem;
$font-size-sm: 1.4rem;
$h1-font-size: 5.2rem;
$h2-font-size: 4rem;
$h3-font-size: 2.8rem;
$h4-font-size: 2.4rem;
$h5-font-size: 2rem;
$headings-margin-top: $spacer * 4.8;
$headings-margin-bottom: $spacer * 4.8;
$paragraph-margin-bottom: 16px;
$headings-line-height: 1.2;
$headings-font-family: $font-family-guardian;

$display1-size:  6rem;
$display2-size:  5.5rem;
$display3-size:  4rem;
$display4-size:  2.8rem;
$display-number-size-lg: 7.2rem;
$display-number-size-sm: 6rem;
$figure-caption-font-size: 2rem;
$display-line-height: 1.28;
$display1-weight: 500;
$display2-weight: 500;
$display3-weight: 500;
$display4-weight: 500;
$display5-weight: 500;

$border-width: 1px;
$input-btn-border-width: $border-width;
$input-btn-padding-y: 1.2rem;
$input-btn-padding-x: 2.4rem;
$input-box-shadow: none;
$dropdown-box-shadow: none;

$input-btn-font-size: $font-size-base;
$input-font-size: $input-btn-font-size;

$btn-border-radius: 2.6rem;
$btn-border-radius-sm: 2.6rem;
$btn-border-width: $input-btn-border-width * 2;
$btn-font-size-sm: $font-size-base;
$btn-font-size-lg: $font-size-base;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-min-width: 21.2rem;
$btn-padding-x-sm: 1.6rem;

$blockquote-size: 3.2rem;
$blockquote-height: 50rem;
$blockquote-font-size: $font-size-base + 0.8;
$blockquote-small-font-size: $font-size-base;

$border-color: $veeam-color-placeholder;
$border-radius: 0;
$input-border-radius: $border-radius;

$card-border-radius: 1rem;
$card-cap-bg: transparent;
$card-spacer-y: 3rem;
$card-spacer-x: 3rem;
$card-columns-spacer: -25rem;
$card-columns-gap: 26px;

$dropdown-border-radius: 0;
$dropdown-spacer: 0;
$dropdown-min-width: 100%;

$navbar-padding-y: $spacer * 2;

$dropdown-font-size: $font-size-base;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x / 2;
$input-border-color: $veeam-color-placeholder;

$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + 4px);
$input-color: $veeam-color-font;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$dropdown-padding-y: 2rem;
$dropdown-item-padding-y: $spacer * 1.2;
$dropdown-item-padding-x: 2.4rem;


$nav-link-padding-x: 2rem;
$nav-link-padding-y: $spacer;

$form-wrapper-width-lg: 81rem;
$form-wrapper-width-md: 61rem;
$search-input-width: 44rem;
$form-wrapper-spacer: 8rem;

$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-size: 2rem;
$custom-switch-width: $custom-control-indicator-size;
$custom-control-indicator-checked-bg: $veeam-color-main;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;
$component-active-bg: $veeam-color-control-bg;
$custom-control-indicator-checked-bg: $component-active-bg;

$mobile-menu-height: 6.4rem;
$mobile-menu-z-index: 200;
$card-z-index: 190;

$controls-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);


$icon-sprites-width: 2.4rem;
$icon-sprites-height: 2.4rem;
$icon-width: 0.6rem;
$icon-height: 0.6rem;

$social-photo-height: 26rem;

$banner-height: 634px;

$img-logo-width: 20rem;

$bg-partial-border-radius: 20px;

$menu-nav-height: 4.2rem;
$menu-nav-width: 4.2rem;
