$veeam-color-green: #00b336;

.main-banner {
  width: 100%;
  max-height: 1080px;
  display: flex;
  justify-content: center;
  position: relative;

  &-image {
    height: auto;
    max-height: 1080px;
    width: 1920px;
  }

  &-title {
    position: absolute;
    left: 11.93%;
    right: 0;
    top: 68.94%;
    bottom: 8.6%;
    background: #FFFFFF;
    border-radius: 20px 0 0 20px;

    &-wrapper {
      height: 100%;
      padding-left: 5%;
      display: table;
      &-middle {
        font-style: normal;
        font-weight: 500;
        font-size: clamp( 1rem, 5vw, 10rem);
        color: #002930;
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 2%;
      }
    }
  }

}

.empowering {
  z-index: 10;
}

.thumbnail {
  position: relative;

  &-play-outer {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background-color: #00B492;
    cursor: pointer;
    border-radius: 100%;

    &-button {
      margin-top: 25px;
      margin-left: 30px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 50px;
      border-color: transparent transparent transparent black;
      opacity: .75;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .75);
    }

    &:hover &-button {
      border-color: transparent transparent transparent #00B492;
    }
  }
}
