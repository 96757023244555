$header-height: 6.6rem;
$header-height-lg: 10rem;
$dropdown-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05), 0 6px 12px rgba(0, 0, 0, 0.07);
$dropdown-width: 32rem;

.header {
  @include media-breakpoint-up(md) {
    border-bottom: $border-width solid $veeam-color-disabled;
  }

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: $veeam-color-control-bg;
  z-index: $mobile-menu-z-index;

  .nav-link {
    align-items: center;

    & > span {
      color: $veeam-color-font;
    }
  }


  .dropdown-menu {
    .dropdown-item {
      text-transform: uppercase;
      font-weight: inherit;

      &:hover,
      &:focus {
        color: $veeam-color-main;
        background: none;

        span::after {
          background: none;
        }
      }
    }
  }



  &.header-desktop {
    transition: background .2s ease-in;

    .container,
    .row,
    .col,
    .navbar,
    .navbar-nav:nth-child(2) .nav-link,
    .navbar-nav:nth-child(2) .dropdown {
      position: static;
    }

    .navbar-expand {
      justify-content: space-between;
    }

    .navbar-nav:nth-child(2) {
      .dropdown-menu.show {
        @include media-breakpoint-up(md) {
          top: $header-height-lg - 1rem;
        }

        border: 0;
        padding-top: $dropdown-padding-y * 4;
        padding-bottom: $dropdown-padding-y * 4;;
        top: $header-height - 1rem;
        box-shadow: 0 8px 20px rgb(0 0 0 / 5%), 0 6px 12px rgb(0 0 0 / 7%);
      }
    }

    .navbar-nav:nth-child(1) {
      .dropdown-menu.show {
        top: $header-height;
        margin-top: 0;
        border: 0;
      }
    }
  }

  &.header-mobile {
    position: fixed;
    z-index: $mobile-menu-z-index;

    .navbar-nav:not(.navbar-toggler) {
      @include media-breakpoint-up(lg) {
        width: calc(100% - #{$img-logo-width / 2 + $spacer * 2});
      }
      .site-switcher {
        .dropdown-toggle {
          display: inline;
          color: $veeam-color-main;
          position: relative;

          &::after {
            left: 100%;
          }
        }
      }
    }

    .navbar-collapse-menu {
      .dropdown-toggle::after {
        @include triangle-outlined(relative, 'right', $veeam-color-main, 3px);
        margin-right: 15px;
        width: $icon-width;
        height: $icon-width;
      }
    }

    .navbar-nav-search {
      .nav-link::after {
        @include pseudo-element {
          width: 24px;
          height: 24px;
          background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -146px -54px;
        }
      }
    }


    .dropdown-menu.show {
      box-shadow: $dropdown-box-shadow;
      position: fixed;
      top: $mobile-menu-height;
      left: 0;
      right: 0;
      width: 100%;
      border: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x - .4;
    }

    .navbar-collapse-menu.collapse {
      .dropdown-menu.show {
        bottom: 0;
      }
    }
  }

  &-with-image {
    @include media-breakpoint-up(xl) {
      justify-content: space-between;
    }

    display: flex;
    align-items: center;
    margin-bottom: 4.8rem;

    .h1,
    .h2,
    .h3 {
      margin-bottom: 0;
      margin-top: 0;

      & + img {
        @include media-breakpoint-up(xl) {
          margin-left: 5rem;
        }

        margin-left: 3.8rem;
      }
    }
  }
}

/*header:not(.bg-transparent) {
  .header.header-desktop {
    .dropdown-toggle {
      &[aria-expanded="false"]::after {
        @include triangle-outlined(relative, 'down', $veeam-color-main, 3px);
      }

      &[aria-expanded="true"]::after {
        @include triangle-outlined(relative, 'up', $veeam-color-main, 3px);
      }
    }
  }
}*/
