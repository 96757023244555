.nav-map {
  min-width: 100%;

  .nav-link {
    @include media-breakpoint-up(md) {
      padding: $nav-link-padding-y $nav-link-padding-x;
    }
    cursor: pointer;
    padding: $nav-link-padding-y $nav-link-padding-x / 2;
    text-transform: uppercase;
  }
}

.map-container {
  overflow: auto;
}

.map-popup {
  min-width: 30rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(24px);
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  z-index: $card-z-index + 1;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-color: $veeam-color-footer-bg;
  }

  @include media-breakpoint-down(md) {
    position: fixed!important;
    left: 10px!important;
    right: 10px!important;
    top: 77px!important;
    bottom: auto!important;
    background-color: $veeam-color-footer-bg;
  }

  &__badge {
    color: $veeam-color-disabled;
    display: flex;
    align-items: center;
  }

  &__title {
    @include media-breakpoint-up(md) {
      font-size: 2.8rem;
    }

    font-size: 2rem;
    margin-bottom: 8px;
  }

  &__count {
    color: $veeam-color-disabled;
  }

  &__description {
    display: flex;

    > a {
      color: $veeam-color-disabled;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .icon-bg-alert {
    @include media-breakpoint-up(lg) {
      width: 30px;
      height: 29px;
    }
  }
}

.map-list {
  &__title {
    color: $veeam-color-green;
    border-bottom: $border-width * 4 solid $veeam-color-green;
    margin-bottom: 2rem;
  }

  &__subtitle {
    margin-bottom: 2rem;
  }

  small {
    opacity: .5;
  }
}
