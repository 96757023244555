$card-homepage-spacer-x: 2rem;
$card-homepage-spacer-x-lg: 2.4rem;
$card-homepage-spacer-y: 2.4rem;
$card-homepage-spacer-y-lg: 3.6rem;

.grid-sizer,
.card-homepage {
  width: 100%;

  @include media-breakpoint-up(md) {
    &:not(.card-homepage--slider) {
      width: 48%;
    }

    &.card-homepage--slider {
      width: 100%;
    }
  }

  @media (min-width: 1366px) {
    &:not(.card-homepage--slider) {
      width: 32%;
    }

    &.card-homepage--slider {
      width: 100% - 34%;
    }
  }

  @media (min-width: 1536px) {
    &:not(.card-homepage--slider) {
      width: 32%;
    }
  }

  @media (min-width: 1920px) {
    &:not(.card-homepage--slider) {
      width: 25%;
      max-width: 39rem;
    }

    &.card-homepage--slider {
      width: 25% * 2;
      max-width: 81rem;
    }
  }
}

.card-homepage {
  @include media-breakpoint-up(md) {
    min-height: 390px;
  }

  position: relative;
  margin-bottom: 30px;
  min-height: 280px;
  border: 0;
  box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);

  .display-1,
  .display-2,
  .display-3 {
    margin-top: 0;
    font-weight: $font-weight-bold;
  }


  &-wrapper {
    margin: 0 auto;
  }

  &-title {
    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size + 0.4;
    }

    font-size: $h3-font-size -0.8;
    margin: 0;

    & + .display-1 {
      @include media-breakpoint-up(md){
          margin-top: 1.6rem;
          margin-bottom: 1.6rem;
      }

      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }

  .card-header {
    @include media-breakpoint-up(lg) {
      padding: $card-homepage-spacer-y-lg $card-homepage-spacer-x-lg;
    }

    padding: 2.8rem $card-homepage-spacer-x;

    span + span {
      font-weight: $font-weight-bold;
    }
  }

  .card-body {
    @include media-breakpoint-up(lg) {
      padding: $card-homepage-spacer-y $card-homepage-spacer-x-lg $card-homepage-spacer-y-lg;
    }

    padding: $card-homepage-spacer-y - 0.8 $card-homepage-spacer-x $card-homepage-spacer-y;
  }

  .card-footer {
    @include media-breakpoint-up(lg) {
      padding: $card-homepage-spacer-y $card-homepage-spacer-x-lg $card-homepage-spacer-y-lg;
    }

    padding: $card-homepage-spacer-y $card-homepage-spacer-x;
  }

  .card-footer {
    padding-top: 0;
  }

  &--culture {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      background: url("//img.veeam.com/careers/global/cards/life-at-veeam/what-it-means_top.png");
      width: 134px;
      height: 140px;
      right: 0;
      top: $card-spacer-y;
    }

    background: url("//img.veeam.com/careers/global/cards/life-at-veeam/what-it-means_bottom.png");
    background-repeat: no-repeat;
    background-position: bottom $card-homepage-spacer-x right;
    background-size: 150px;
  }

  &--vibes {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/life-at-veeam/vibes.png");
    background-repeat: no-repeat;
    background-position: top $card-homepage-spacer-x right $card-homepage-spacer-x;
    background-size: 200px;

    .card-body {
      @include media-breakpoint-up(md) {
        margin-top: 100px;
      }

      margin-top: 30px;
    }
  }

  &--values {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/life-at-veeam/values.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 250px;

    .card-body {
      width: 80%;
      margin-bottom: 100px;
    }
  }

  &--courses {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/life-at-veeam/courses.png");
    background-repeat: no-repeat;
    background-position: top $card-homepage-spacer-x right $card-homepage-spacer-x;
    background-size: 120px;

    .card-body {
      @include media-breakpoint-up(md) {
        margin-top: 110px;
      }

      margin-top: 90px;
    }
  }

  &--award-01 {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/award_01.png");
    background-repeat: no-repeat;
    background-position: bottom $card-homepage-spacer-x right $card-homepage-spacer-x;
    background-size: 110px;

    .card-body {
      margin-bottom: 130px;
    }
  }

  &--award-02 {
    @include media-breakpoint-up(md) {
      background-position: bottom -10px right $card-homepage-spacer-x;
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/award_02.png");
    background-repeat: no-repeat;
    background-size: 240px;
    background-position: bottom -#{$card-homepage-spacer-x} right $card-homepage-spacer-x;

    .card-body {
      @include media-breakpoint-up(md) {
        width: 80%;
      }

      margin-bottom: 60px;
    }
  }

  &--social {
    @include media-breakpoint-up(md) {
      background-position: bottom right 10px;
      background-size: 100%;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/social.png");
    background-repeat: no-repeat;
    background-position: bottom right $card-homepage-spacer-x;
    background-size: 70%;
  }

  &--external {
    min-height: 500px;
    background: url("//img.veeam.com/careers/global/cards/external/support.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 100%;
  }

  &--diveeam {
    min-height: 500px;
    background: url("//img.veeam.com/careers/global/cards/about-us/diveeam_banner.png");
    background-repeat: no-repeat;
    background-position: right -2px bottom;
    background-size: cover;
  }

  &--opportunities {
    min-height: 600px;
    background: url("//img.veeam.com/careers/global/cards/life-at-veeam/opportunities_banner.png");
    background-repeat: no-repeat;
    background-position: right -2px bottom;
    background-size: contain;
  }

  &--graduates {
    min-height: 650px;
    background: url("//img.veeam.com/careers/global/cards/about-us/graduates_dotts.png");
    background-repeat: no-repeat;
    background-position: right -2px bottom;
    background-size: contain;

    .card-footer {
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  &--video {
    @include media-breakpoint-up(md) {
      background-position: bottom $card-homepage-spacer-x right 10px;
      background-size: 54%;
    }

    background: url("//img.veeam.com/careers/global/cards/video/video.png");
    background-repeat: no-repeat;
    background-position: bottom $card-homepage-spacer-x right $card-homepage-spacer-x;
    background-size: 50%;
    cursor: pointer;
  }

  &--growth {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    &::before {
      @include media-breakpoint-up(md) {
        background-size: auto;
        right: 0;
      }

      content: '';
      position: absolute;
      display: block;
      background: url("//img.veeam.com/careers/global/cards/about-us/growth_top.png");
      background-size: 50%;
      background-repeat: no-repeat;
      width: 134px;
      height: 140px;
      right: -30px;
      top: $card-spacer-y / 2;
      z-index: 0;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/growth.png");
    background-repeat: no-repeat;
    background-size: 160px;
    background-position: bottom -5px right -17px;
    z-index: 100;

    .card-body {
      width: 78%;
    }
  }

  &--competing {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/competing.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 250px;

    .card-body {
      width: 72%;
    }
  }

  &--stories {
    @include media-breakpoint-up(md) {
      background-position: bottom right -30px;
    }

    @include media-breakpoint-up(lg) {
      background-position: center;
    }

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--success {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/success.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100px;

    .card-body {
      width: 70%;
    }
  }

  &--champions {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/cards/about-us/champions.png");
    background-repeat: no-repeat;
    background-position: top $card-homepage-spacer-x right;
    background-size: 200px;

    .card-body {
      @include media-breakpoint-up(md) {
        margin-top: 130px;
      }

      margin-top: 90px;
    }
  }

  &--community {
    @include media-breakpoint-up(md) {
      background-size: auto;
    }

    .card-header {
      background: url(//img.veeam.com/careers/global/apply/hand_3.png) no-repeat right;
      background-position-y: -93px;
    }

    .card-body {
      background: url(//img.veeam.com/careers/global/apply/hand_2.png) no-repeat;
      background-position: right bottom 30px;
    }

    position: relative;
    background: url(//img.veeam.com/careers/global/apply/hand_1.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    min-height: 600px;
  }
}


//@todo: move whole slider thing to slider.scss
.card-homepage.card-homepage--slider {
  @include media-breakpoint-up(xl) {
    height: 540px;
  }

  background: transparent;
  border: 0;
  height: 380px;
  box-shadow: none;

  .card-header {
    @include media-breakpoint-up(lg) {
      padding-top: 4.8rem;
    }

    padding-top: 2.8rem;
  }

  .slider-inner {
    @include media-breakpoint-up(md) {
      padding: 0 8rem;

    }

    @include media-breakpoint-up(xl) {
      height: 500px;
    }

    height: 330px;
    border-radius: 1rem;
  }

  .slider-inner--vacancies {
    @include media-breakpoint-up(md) {
      background-size: 80%;
      background-position: 100%;
    }

    @include media-breakpoint-up(lg) {
      background-position: 100%;
      background-size: 55%;
    }

    @include media-breakpoint-up(xl) {
      background-position: 0 0;
      background-size: cover;
    }

    position: relative;
    background: url("//img.veeam.com/careers/global/cards/slider/vacancies.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 75%;
    box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);

    .card-body {
      @include media-breakpoint-up(xl) {
        margin-top: 20px;
      }

      padding-top: 0;
    }
  }


  .slider-inner--grows {
    @include media-breakpoint-up(sm) {
      background-size: 45%;
    }

    @include media-breakpoint-up(lg) {
      background-size: 30%;
    }

    @include media-breakpoint-up(xl) {
      background-size: auto;
    }

    position: relative;
    background: url("//img.veeam.com/careers/global/cards/slider/ashleigh.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: bottom right -10px;
    box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);

    &::before {
      @include media-breakpoint-up(md) {
        background-size: 100%;
        left: 300px;
        top: 20px;
        width: 140px;
      }

      @include media-breakpoint-up(lg) {
        top: 40px;
      }

      @include media-breakpoint-up(xl) {
        width: 222px;
        left: 270px;
        top: 80px;
      }

      content: '';
      display: block;
      position: absolute;
      background: url("//img.veeam.com/careers/global/cards/slider/grows.png");
      background-size: 60%;
      background-repeat: no-repeat;
      width: 180px;
      height: 130px;
      left: 2rem;
      top: 80px;
    }

    .card-body {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 70px;
      }

      margin-top: 60px;
    }
  }

  .slider-inner--cares {
    @include media-breakpoint-up(md) {
      background-size: 40%;
      background-position: top $card-spacer-y left 80%;
    }

    @include media-breakpoint-up(lg) {
      background-position: top 4.8rem left 80%;
    }

    @include media-breakpoint-up(xl) {
      background-size: auto;
      background-position: top 4.8rem left 70%;
    }

    background: url("//img.veeam.com/careers/global/cards/slider/shades.png");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: top 10rem left $card-spacer-x;

    .card-body {
      @media (max-width: 320px) {
        margin-top: 30px;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      margin-top: 50px;
    }
  }

  .slider-inner--values {
    @include media-breakpoint-up(md) {
      background-size: 60%;
      background-position: bottom 30px left 100px;
    }

    @include media-breakpoint-up(lg) {
      background-size: auto;
      background-position: bottom 20px left 100px;
    }

    @include media-breakpoint-up(xl) {
      background-position: bottom 48px left 100px;
      background-size: 70%;
    }

    background: url("//img.veeam.com/careers/global/cards/slider/stars.png");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: bottom 30px left $card-spacer-x;

    .card-body {
      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
    }
  }

  a:hover {
    text-decoration: none !important;
  }

}

