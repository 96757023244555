$border-color: #cdcdcd;

.container-footer {
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  
  .navbar-expand {
    justify-content: flex-end;
  }
}

footer.footer {
  @include media-breakpoint-up(md) {
    padding: $card-spacer-y 0;
    background-color: transparent;
  }
  border-top: 1px solid $border-color;
  position: relative;
  padding: $card-spacer-y 0;
  background-color: $veeam-color-body;
  align-items: center;
}
