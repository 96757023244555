html {
  font-size: 10px;
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $font-family-guardian-text;
  background-color: $veeam-color-control-bg;
  color: $veeam-color-font;
  font-size: $font-size-base;
  line-height: $line-height-base;
  overflow-x: hidden;
  overflow-y: scroll;
}

ul,
ol {
  padding-inline-start: $spacer * 2.4;

  li {
    margin-bottom: $spacer * 2.4;
  }
}
