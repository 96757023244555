.slider {
  &-wrapper {
    @include media-breakpoint-up(md) {
      top: -150px;
    }

    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
  }
}

