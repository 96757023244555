$icon-circle-diameter: 6.8rem;


.icon-circle {
  align-items: center;
  display: flex;
  justify-content: center;
  height: $icon-circle-diameter;
  width: $icon-circle-diameter;
  position: relative;
  border-radius: 50%;

  &.icon-circle--green {
    background: $veeam-color-green;
    color: $veeam-color-control-bg;

    & + h3 {
      flex: 3;
    }
  }
}

.icon-checked--circled {
  display: inline-flex;
  border: 2px solid $veeam-color-main;
  border-radius: 50%;
  width: 3.7rem;
  height: 3.7rem;
  position: relative;

  &::before {
    display: block;
    content: '';
    height: 27px;
    width: 12px;
    border: solid $veeam-color-main;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    left: 19px;
    margin-top: -5px;
    margin-left: -2px;
    position: absolute;
  }

  & + span {
    margin-left: $spacer * 2.5;
    flex: 1;
  }
}

.icon-search_green {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-top: 2px;
  background: url('//img.veeam.com/careers/icons/icon_search_green.png') no-repeat;
}

