.blockquote {
  position: relative;
  text-align: left;
  padding-left: $blockquote-size * 1.5;

  &::before {
    content: "";
    background: url("//img.veeam.com/careers/img/img_ru/quote.png") no-repeat;
    display: block;
    left: 0;
    height: $blockquote-size;
    width: $blockquote-size;
    position: absolute;
  }

  &__text_sm {
    font-size: $blockquote-small-font-size;
  }

  &.blockquote-fixed {
    @include media-breakpoint-up(md) {
      min-height: $blockquote-height - 5;
    }

    @include media-breakpoint-up(lg) {
      min-height: $blockquote-height + 7;
    }

    min-height: $blockquote-height;
  }

  &-footer {
    color: $veeam-color-font;
    font-size: $blockquote-small-font-size;
    margin-top: $blockquote-font-size;
  }

  &.blockquote_sm {
    @include media-breakpoint-up(md) {
      min-height: $blockquote-height - 14;
    }

    @include media-breakpoint-up(lg) {
      min-height: $blockquote-height - 20;
    }

    min-height: $blockquote-height + 2;
  }
}


