.icon-bg-arrow_bottom_gray {
  width: 13px; height: 8px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -150px -114px;
}

.icon-bg-alert {
  @include media-breakpoint-up(md) {
    width: 30px; height: 29px;
  }

  @include media-breakpoint-up(lg) {
    width: 20px; height: 29px;
  }
  width: 36px; height: 29px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -150px -10px;
}

.icon-bg-arrow_bottom_green {
  width: 13px; height: 8px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -10px -150px;
}

.icon-bg-arrow_bottom_white {
  width: 13px; height: 8px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -43px -150px;
}

.icon-bg-arrow_right_green {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -62px -10px;
}

.icon-bg-checkbox {
  width: 12px; height: 10px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -150px -84px;
}

.icon-bg-faq {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -10px -62px;
}

.icon-bg-globe_green {
  width: 20px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -54px -63px;
}

.icon-bg-globe_white {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -106px -11px;
}

.icon-bg-person {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -106px -55px;
}

.icon-bg-play {
  width: 32px; height: 32px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -10px -10px;
}

.icon-bg-arrow_top_gray {
  width: 18px; height: 10px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -150px -54px;
}

.icon-bg-search {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -10px -106px;
}

.icon-bg-star {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -54px -106px;
}

.icon-bg-whitepaper {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -98px -106px;
}

.icon {
  display: block;
}

.bg-arrow_common_right {
  width: 19px;
  height: 17px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -146px -98px;
}

.bg-arrow_right_darkgreen {
  width: 8px;
  height: 13px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -190px -10px;
}

.bg-arrow_right_darkgreen {
  width: 8px;
  height: 13px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -190px -10px;
}

.bg-btn_left {
  width: 48px;
  height: 48px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -10px -10px;
}

.bg-btn_down {
  width: 40px;
  height: 40px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -10px -78px;
}

.bg-btn_right {
  width: 40px;
  height: 40px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -70px -78px;
}

.bg-icon_heart {
  width: 16px;
  height: 16px;
  background: url('//img.veeam.com/careers/landing/img/sprites/css_sprites.png') -10px -52px;
}

.menu-burger {
  width: 24px;
  height: 24px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -146px -10px;
}

.btn_up {
  width: 48px;
  height: 48px;
  background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -78px -10px;
}

.sprites {
  display: inline-block;
  & + .sprites {
    margin-left: 2.5rem;
  }

  &.bg-facebook {
    width: 40px; height: 40px;
    background: url('//img.veeam.com/careers/global/sprites-footer/footer-sprites.png') -10px -10px;
  }

  &.bg-instagram {
    width: 40px; height: 40px;
    background: url('//img.veeam.com/careers/global/sprites-footer/footer-sprites.png') -70px -10px;
  }

  &.bg-linkedin {
    width: 40px; height: 40px;
    background: url('//img.veeam.com/careers/global/sprites-footer/footer-sprites.png') -10px -70px;
  }

  &.bg-twitter {
    width: 40px; height: 40px;
    background: url('//img.veeam.com/careers/global/sprites-footer/footer-sprites.png') -70px -70px;
  }

  &.bg-youtube {
    width: 40px; height: 40px;
    background: url('//img.veeam.com/careers/global/sprites-footer/footer-sprites.png') -130px -10px;
  }

  &.bg-play {
    width: 24px; height: 24px;
    background: url('//img.veeam.com/careers/global/icons/icons_homepage.png') -10px -10px;
  }

  &.bg-play_success {
    width: 24px; height: 24px;
    background: url('//img.veeam.com/careers/global/icons/play-success.png');
  }

  &.bg-whitepaper_green {
    width: 21px; height: 22px;
    background: url('//img.veeam.com/careers/global/icons/icons_homepage.png') -54px -10px;
  }

  &.bg-whitepaper_success {
    width: 21px; height: 22px;
    background: url('//img.veeam.com/careers/global/icons/icons_homepage.png') -95px -10px;
  }

  &.bg-whitepaper_white {
    width: 21px; height: 22px;
    background: url('//img.veeam.com/careers/global/icons/icons_homepage.png') -10px -54px;
  }

}

.icon-bg-person-green {
  width: 24px; height: 24px;
  background: url('//img.veeam.com/careers/icons/icon_user_green.png');
  background-repeat: no-repeat;
  margin-top: 2px;
}
