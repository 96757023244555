@mixin headings-md($header) {
  @if $header == 'h1' {
    h1,
    .h1 {
      margin-top: 4.8rem;
      margin-bottom: 3.2rem;
      font-size: 4rem;
    };
  } @else if $header == 'h2' {
    h2,
    .h2 {
      margin-top: 6rem;
      margin-bottom: 3.2rem;
      font-size: 2.8rem
    }
  } @else if $header == 'h3' {
    h3,
    .h3 {
      font-size: 2.4rem;
      margin-top: 3.2rem;
      margin-bottom: 2.4rem;
    };
  }  @else if $header == 'h4' {
    h4,
    .h4 {
      font-size: 2.4rem;
      margin-top: 2.4rem;
      margin-bottom: 1.6rem;
    };
  }@else if $header == 'display-1' {
    .display-1 {
      font-size: 4rem;
    };
  }  @else if $header == 'display-2' {
    .display-2 {
      font-size: 4rem;
    }
  } @else if $header == 'display-3' {
    .display-3 {
      font-size: 4rem;
    }
  } @else if $header == 'display-4' {
    .display-4 {
      font-size: 2.4rem;
    }
  }
}


@mixin headings-sm($header) {
  @if $header == 'h1' {
    h1,
    .h1 {
      margin-top: 2rem;
      margin-bottom: 1.2rem;
      font-size: 2.8rem;
    };
  } @else if $header == 'h2' {
    h2,
    .h2 {
      margin-top: 2rem;
      margin-bottom: 1.2rem;
      font-size: 2.4rem
    }
  } @else if $header == 'h3' {
    h3,
    .h3 {
      margin-top: 1.2rem;
      margin-bottom: 2rem;
      font-size: 2.4rem;
    };
  }  @else if $header == 'h4' {
    h4,
    .h4 {
      margin-top: 2rem;
      margin-bottom: 1.6rem;
      font-size: 2rem;
    };
  }@else if $header == 'display-1' {
    .display-1 {
      font-size: 2.8rem;
    };
  }  @else if $header == 'display-2' {
    .display-2 {
      font-size: 2.4rem;
    }
  } @else if $header == 'display-3' {
    .display-3 {
      font-size: 2.8rem;
    }
  } @else if $header == 'display-4' {
    .display-4 {
      font-size: 2rem;
    }
  }
}


@mixin disable-native-icons {
  border: 0
}

@mixin pseudo-element {
  content: "";
  display: inline-block;
  @content
}

@mixin headers-spacing($header) {
  @if $header == 'h1' {
    h1,
    .h1 {
      margin-top: 8rem;
      margin-bottom: 4.8rem;
      font-size: 5.2rem;
    }
  } @else if $header == 'h2' {
    h2,
    .h2 {
      margin-top: 8rem;
      margin-bottom: 4.8rem;
      font-size: 4rem;
    }
  } @else if $header == 'h3' {
    h3,
    .h3 {
      margin-top: 4.8rem;
      margin-bottom: 2.8rem;
      font-size: 2.8rem;
    }
  }  @else if $header == 'h4' {
    h4,
    .h4 {
      margin-top: 3.2rem;
      margin-bottom: 1.6rem;
      font-size: 2.4rem;
    }
  } @else if $header == 'display-1' {
    .display-1 {
      font-size: $display1-size;
    }
  }  @else if $header == 'display-2' {
    .display-2 {
      font-size: $display2-size;
    }
  } @else if $header == 'display-3' {
    .display-3 {
      font-size: $display3-size;
    }
  } @else if $header == 'display-4' {
    .display-4 {
      font-size: $display4-size;
    }
  }
}


@mixin prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

@mixin bg-with-picture($src) {
  background: url($src) no-repeat;
  background-size: cover;
  background-color: $veeam-color-footer-bg;
}

@mixin triangle-outlined($position, $direction, $color, $size) {
  content: '';
  display: inline-block;
  position: $position;
  border: solid $color;
  border-width: 0 3px 3px 0;
  padding: $size;
  @content;

  @if ($direction == 'up') {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  } @else if ($direction == 'down') {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  } @else if ($direction == 'left') {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  } @else if ($direction == 'right') {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

@mixin bg-partial($background, $direction) {
  content: '';
  display: block;
  height: 100%;
  width: 90%;
  position: absolute;
  background-color: $background;

  @if($direction == 'left') {
    border-top-left-radius: $bg-partial-border-radius;
    border-bottom-left-radius: $bg-partial-border-radius;
    right: 0;
  } @else if ($direction == 'right') {
    border-top-right-radius: $bg-partial-border-radius;
    border-bottom-right-radius: $bg-partial-border-radius;
    left: 0;
  }

}


@mixin triangle-filled($size, $position, $direction, $background-color) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: $position;
  @content;

  @if($direction == 'up') {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $background-color;
  } @else if($direction == 'down') {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $background-color;
  } @else if($direction == 'left') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $background-color;
  } @else if($direction == 'right') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $background-color;
  }


}


@mixin arrow-graphics($image-src) {
  content: '';
  position: absolute;
  display: block;
  z-index: 200;
  background: url($image-src);
  background-repeat: no-repeat;
  @content;
}
