.card-columns {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-up(lg) {
    column-count: 3;
  }

  column-count: 1;
  margin-left: 0;
  margin-right: 0;

  .card +.card {
    margin-top: 0;
  }

  &.card-columns__card-columns-vacancies {
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
  }

  &.card-columns__card-columns-shadowed {
    .card {
      position: static;
      border: 0;
      box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);
    }
  }

  &.card-columns--homepage {
    min-width: 100%;
    column-count: 4;
  }

  &.card-columns__card-columns-map {
    @include media-breakpoint-up(md) {
      column-count: 4;
    }

    column-gap: 12rem;
  }

}
