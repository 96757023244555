$btn-height: 5rem;
$btn-width: 5rem;

.form-control:not(.form-search) {
  &:focus {
    border-color: $veeam-color-main;
    box-shadow: none;
  }

  &[type="search"]::-webkit-search-cancel-button {
    position:relative;
    right:20px;
  }


  &::placeholder {
    color: $veeam-color-placeholder;
  }

  & + .form-control {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    margin-top: $spacer * 1.6;
  }


  & + .btn  {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    margin-top: $spacer * 2.4;
  }
}

form {
  @include media-breakpoint-up(md) {
    flex-flow: row;
  }

  display: flex;
  flex-flow: column;
}

.form-group {
  .dropdown {
    &-toggle {
      align-items: center;
      background: $veeam-color-control-bg;
      justify-content: space-between;
      padding: $input-padding-y $input-padding-x;

      /*&::after {
        background: url(//img.veeam.com/careers/icons/sprites.png) -150px -109px;
      }*/

      &:active,
      &[aria-expanded="true"] {
        background: $veeam-color-green;
        color: $veeam-color-control-bg;

        &::after {
          background: url('//img.veeam.com/careers/icons/sprites.png') -43px -145px;
        }
      }
    }

    &-menu {
      transform: none !important;
      top: $btn-height !important;

      &.show {
        border: 0;
        box-shadow: $controls-box-shadow;
      }
    }
  }

  .icon-bg-search {
    display: inline-block;
    position: absolute;
    right: $input-btn-padding-x;
    top: $input-btn-padding-y + 0.3;
  }

  .form-control:focus + .icon-bg-search {
    display: none;
  }
}

.form-search {

  input[type="search"] {
    @include media-breakpoint-up(md) {
      width: $search-input-width;
      border-radius: $spacer * 10;
      margin-bottom: 0;
      /*border: $border-width solid $veeam-color-placeholder;*/
      padding: $input-btn-padding-y $input-btn-padding-x;
    }

    /*background: url(//img.veeam.com/careers/icons/icon_search_gray.png);*/
    background-color: $veeam-color-control-bg;
    background-repeat: no-repeat;
    background-position-x: calc(100% - #{$input-btn-padding-x});
    background-position-y: 50%;
    padding-right: $input-padding-x * 2;
    border: $border-width solid $veeam-color-main;
    color: $veeam-color-placeholder-pressed;
  }

  .form-control + .btn {
    @include media-breakpoint-down(md) {
      margin-top: 0;
      height: 5rem;
      margin-left: 0;

      &:active,
      &:focus {
        box-shadow: none !important;
        background-color: transparent;
        border: 0;
      }
    }
  }

}

.form-subscribe {
  .form-control + .form-control {
    @include media-breakpoint-up(md) {
      margin-right: $spacer * 2.4;
    }
    margin-left: -2px;
  }
}

.form-wrapper {
  @include media-breakpoint-up(lg) {
    width: $form-wrapper-width-md;
    border-radius: $spacer * 10;
    background: $veeam-color-control-bg;
    margin-top: $form-wrapper-spacer;
    padding: $spacer * 3.5;
  }

  @include media-breakpoint-up(lg) {
    width: $form-wrapper-width-lg;
    border-radius: $spacer * 10;
  }

  z-index: 190;

  form {
    @include media-breakpoint-down(md) {
     flex-flow: row;
    }
  }
}


