.vacancies-filter {
  &__cta {
    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      flex: 2;
      text-align: right;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3rem;
    }


    font-size: $font-size-base;
    font-weight: 500;
  }

  > .form-group {
    &:not(:last-of-type) {
      margin-bottom: 22px;
    }
    &:last-of-type {
      @include media-breakpoint-up(md) {
        margin-top: 48px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 72px;
      }

      margin-top: 28px;
    }
  }

  &__label {
    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 48px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 60px;
        line-height: 68px;;
    }

    display: block;
    font-size: 20px;
    line-height: 28px;
    margin-right: 8px;
    margin-bottom: 0;
    padding-right: 20px;
    white-space: nowrap;
  }

  .dropdown--fixed {
    @include media-breakpoint-up(xl) {
      min-width: 630px;
    }
  }


  .dropdown-toggle {
    @include media-breakpoint-up(md) {
      font-size: 36px;
      line-height: 48px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 60px;
      line-height: 68px;;
    }

    font-size: $h3-font-size;
    line-height: 46px;
    font-style: italic;
    padding: 0 !important;
    padding-right: 1.2rem !important;
    border-bottom: 4px solid #232323 !important;

    &[type="search"] {
      @include media-breakpoint-up(md) {
        width: 60%;
        background-size: 60px;
        background-position: left center;
        padding-left: 6rem !important;
      }
      @include media-breakpoint-up(xl) {
        width: 52%;
        background-size: 60px;
        background-position: left top 8px;
        padding-left: 7rem !important;
      }

      background: url("//img.veeam.com/careers/icons/ic_magnifier.png") no-repeat;
      background-position: left center;
      padding-left: 7rem !important;
      padding-right: 0 !important;
      background-size: 50px;
      height: auto;
      border: 0;

      &::-webkit-search-cancel-button{
        display: none;
      }

      &::placeholder {
        color: $veeam-color-disabled;
      };
    }
  }

  .form-group .dropdown-menu {
    @include media-breakpoint-up(xl) {
      top: 8rem !important;
    }

    top: 6rem !important;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  .form-group .dropdown-toggle:active,
  .form-group .dropdown-toggle[aria-expanded="true"] {
    background: #fff;
    color: $veeam-color-main;
  }

  .dropdown-menu .dropdown-item {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;

    &:hover {
      background-color: transparent;
      color: $veeam-color-main;
    }
  }

  .dropdown-toggle:hover,
  .dropdown-toggle:focus,
  .dropdown-toggle:active {
    color: $veeam-color-main;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: $veeam-color-main;
    background-color: transparent;
  }

  .dropdown-toggle {
    @include media-breakpoint-up(xl) {
      &[aria-expanded="false"]::after {
        @include triangle-outlined(relative, 'down', $veeam-color-main, 6px);
      }

      &[aria-expanded="true"]::after {
        @include triangle-outlined(relative, 'up', $veeam-color-main, 6px);
      }
    }

    &[aria-expanded="false"]::after {
      @include triangle-outlined(relative, 'down', $veeam-color-main, 6px);
    }

    &[aria-expanded="true"]::after {
      @include triangle-outlined(relative, 'up', $veeam-color-main, 6px);
    }
  }

  .btn.btn-success {
    max-width: 170px;
  }

  .scrollarea-content {
    min-width: 25rem;

    .dropdown-item {
      background-color: $veeam-color-control-bg !important;
    }
  }

}

.area {
  margin: 0 auto;
  max-height: 300px;
}
