$card-min-height: 47.5rem;
$card-body-height-md: 31.5rem;


.card-deck.card-deck-vacancies-foldable {
  .card {
    @include media-breakpoint-down(md) {
      margin-bottom: $card-spacer-y;

      .card-header {
        .display-3 {
          margin-bottom: 0;
        }
      }

      .text-slogan {
        display: none
      }
    }

    justify-content: space-between;
    /*min-height: $card-min-height;*/
    min-width: 20%;
    transition: min-width 0.8s;

    .btn:not(.btn-link) {
      @include media-breakpoint-up(lg) {
        align-self: flex-end;
      }
    }

    .card-body {
      @include media-breakpoint-up(md) {
        padding-bottom: 3rem;
       /* min-height: auto;*/
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }

      /*min-height: $card-body-height-md;*/
      justify-content: flex-start;
      padding-bottom: 0;

      h5 {
        font-weight: 600;
      }
    }

    .card-footer {
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end !important;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      justify-content: space-between;
      left: 0;

      .btn-toggler {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }


      .footer-left,
      .footer-right {
        @include media-breakpoint-up(lg) {
          display: none;
          align-items: center;
        }

        display: flex;
        flex-flow: row;
        align-items: flex-start;
      }

      .footer-left {
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
        margin-bottom: $card-spacer-y;
      }
    }

    &.active {
      min-width: 65%;


      .text-slogan {
        display: none
      }

      .card-header {
        .display-3 {
          margin-bottom: 0;
        }
      }

      .card-body {
        display: flex;
        justify-content: flex-start;
      }

      .card-footer {
        justify-content: space-between;

        .btn-outline-success {
          display: none;
        }

        .footer-left {
          @include media-breakpoint-up('md') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;

            ul, li {
              margin-bottom: 0;
            }
          }

          flex-flow: row;
          align-items: flex-start;
          margin-bottom: $card-spacer-y;
        }

        .footer-right {
          @include media-breakpoint-down(md) {
            flex-flow: column;
            align-items: flex-start;
          }

          display: flex;
        }
      }
    }
  }
}


.card-deck-vacancies {
  border-top: 4px solid $veeam-color-placeholder;
  border-bottom: 4px solid $veeam-color-placeholder;

  .card {
    @include media-breakpoint-up(md) {
      &:nth-child(1),
      &:nth-child(2) {
        border-top: 0 !important;
      }
    }

    border: 0;
    background-color: transparent;
    margin-bottom: 0;
    border-radius: 0;

    &-header,
    &-footer {
      padding-left: 0;
      padding-right: 0;
    }

    &-body {
      padding: 0;
    }

    &.card--shadowed {
      box-shadow: none !important;
    }

    & + .card {
      border-top: $border-width solid $veeam-color-placeholder;
    }
  }
}
