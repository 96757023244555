.awards {
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacer * 2;
  border-bottom: $border-width solid $veeam-color-disabled;

  &:not(:first-child) {
    padding-top: $spacer * 2;
  }

  &::after {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  & > .btn {
    margin-bottom: 0;
  }

  & > img {
    display: none;
    position: absolute;
    right: 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.04);
    max-width: 160px;
  }
}
