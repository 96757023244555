$mobile-menu-height: 6.6rem;

#root {
  width: 100%;
}

a {
  color: $veeam-color-main;
}

.careers-app {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.bg-blue-custom {
  background-color: white;
  border-radius: 20px;
  border: 3px solid $veeam-color-light-green;
  margin-bottom: 24px;
  &:hover {
    cursor: pointer;
    color: white;
    border: 3px solid $veeam-color-blue;
    background-color: $veeam-color-blue;
  }
}

.bg-light-green {
  background-color: $veeam-color-light-green;
  color: black;
  position: relative;
}

.bg-green,
.bg-dark,
.bg-danger {
  color: $veeam-color-control-bg;
  position: relative;
}

a.bg-dark:hover,
a.bg-dark:focus {
  background-color: #004550 !important;
}

a.bg-danger:hover {
  background-color: #005f4b !important;
}

a.bg-green:hover {
  background-color: #00B492 !important;
}

a.bg-info:hover {
  background-color: $veeam-color-control-bg !important;
}

.container-fluid {
  position: relative;

  &.container-fluid__fixed {
    max-width: 1680px;
  }
}

.container-main {
  /*@media (min-width: 1600px) {
    background: url("//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png") no-repeat;
    background-position: 0 43%;
  }*/
}

.container-spacer {
  @include media-breakpoint-up(lg) {
    padding-top: 6rem;
    padding-bottom: 10rem;
  }
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;

  & + .container-spacer {
    padding-top: 0;
  }

  & > h1,
  & > h2 {
    margin-top: 0 !important;
  }
}

.container-spacer-lg {
  @include media-breakpoint-up(lg) {
    padding-top: 18rem;
    padding-bottom: 10rem;
  }

  padding-top: 10rem;
  padding-bottom: 5rem;
}

.block-spacer {
  @include media-breakpoint-up(md) {
    margin-top: $spacer * 6;
    margin-bottom: $spacer * 4;
  }
  margin-top: $spacer * 2.4;
  margin-bottom: $spacer * 2.4;
}

.block-spacer-mobile {
  @include media-breakpoint-down(md) {
    margin-top: $spacer * 2.4;
    margin-bottom: $spacer * 2.4;
  }
}

.content-wrapper {
  margin-bottom: 6rem;
}

.block-spacer-top {
  margin-top: 6rem;
}

.header-spacer {
  margin-bottom: $spacer * 4.8;
}


.header-custom-spacer {
  margin-bottom: $spacer * 3.2 !important;
}

.header-custom-spacer-md {
  margin-bottom: $spacer * 1.2 !important;
}

.banner-spacer {
  @include media-breakpoint-up(md) {
    padding-top: 8rem;
    padding-bottom: 8rem;

  }
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.banner-spacer-md {
  @include media-breakpoint-up(md) {
    padding-top: 12rem;
    padding-bottom: 12rem;

  }
  padding-top: 10rem;
  padding-bottom: 5rem;
}



.banner-spacer-lg {
  @include media-breakpoint-up(lg){
    padding-top: 12rem;
    padding-bottom: 8rem;
  }

  @media (min-width: 1500px) {
    padding-top: 22rem;
    padding-bottom: 12rem;
  }

  padding-top: 5rem;
  padding-bottom: 2rem;
}




.block-spacer-bottom {
  margin-bottom: 10rem;
}

.z-index-overflow {
  z-index: 200;
}

.block-news {
  @include media-breakpoint-down(md) {
    align-items: flex-start !important;

    .btn-link-cta {
      padding: 0;
    }
  }
}

.shareaholic-share-buttons-container {
  max-width: 100%;
}

:focus {
  outline: none;
}

.container-fluid {

  &.container-main {
    @include media-breakpoint-up(lg) {
      margin-top: 90px;
    }

    flex: 1 0 auto;
    margin-top: $mobile-menu-height;
    padding: 0;
  }
}

.fixed-height {
  @include media-breakpoint-up(sm) {
    height: 460px;
  }

  @include media-breakpoint-up(lg) {
    height: 510px;
  }

  height: 500px;
}

@media (max-width: 576px) {
  .mobile-wrapper {
    height: 408px;
    overflow: hidden;
    position: relative;
    border-bottom: 10px solid #fff;
  }
}

.container-relative {
  @include media-breakpoint-up(xl) {
    position: relative;
    top: 100px;
  }
}

.static-banner {
  position: absolute;

  z-index: 2; /* above items */
}

.overflow-visible {
  overflow: visible!important;
  z-index: 999;
}


.font-weight-strong {
  font-weight: 600;
}

.font-weight-stronger {
  font-weight: 500;
}

a.text-danger-light:hover {
  color: $veeam-color-sapphire-light !important;
  text-decoration: none;
}

.wistia_click_to_play {
  min-width: 100%;
}

.mdc-checkbox__checkmark-path {
  fill: none;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

p {
  word-break: break-word;
}

.content-body {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.marker-success::marker {
  color: $veeam-color-main;
}

//tmp styles

