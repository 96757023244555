$video-height-sm: 396px;
$video-height: 526px;
$video-height-lg: 648px;
$video-width: 1156px;


[class*="bg"] {
  position: relative;
}

.bg {
  &-green-gender {
    background-color: $veeam-color-green;
    color: $veeam-color-sapphire-dark
  }
  
  &-hands {
    @include media-breakpoint-up(md) {
      background: url("//img.veeam.com/careers/global/apply/banner_allhands.png");
      background-repeat: no-repeat;
      background-position: right -130px top -50px;
      background-size: 75%;
    }

    @include media-breakpoint-up(lg) {
      background-size: 59%;
    }

    @media (min-width: 1600px) {
      background-size: auto;
    }

    background: url("//img.veeam.com/careers/global/apply/hand_2.png");
    background-repeat: no-repeat;
    background-position: bottom 40px right -30px;

    .img {
      position: relative;

      &.img__top {
        content: '';
        top: 0;
        left: 0;
      }

      &.img__bottom {
        content: '';
        bottom: 0;
        right: 0;
      }
    }

    .container > div {
      @include media-breakpoint-up(md) {
        width: 65%;
      }

      width: 75%;
    }
  }


  &-pattern {

    &-left,
    &-right {
      @include media-breakpoint-up(xl) {
        position: absolute;
        display: block;
        height: 100%;
        width: 223px;
      }
    }

    &-left {
      @include media-breakpoint-up(xl) {
        bottom: -20px;
        left: -120px;
      }

      @media (min-width: 1440px) {
        left: 0;
      }

      &.pattern-on-dark {
        @include media-breakpoint-up(xl) {
          background: url("//img.veeam.com/careers/global/patterns-life/left.png") no-repeat;
        }
      }
    }

    &-right {
      @include media-breakpoint-up(xl) {
        right: 0;
      }

      &.pattern-on-dark {
        @include media-breakpoint-up(xl) {
          background: url("//img.veeam.com/careers/global/patterns-life/right.png");
          background-repeat: no-repeat;
        }
      }
    }
  }


  &-partial {
    &.bg-partial--light {
      @media (min-width: 1680px) {
        background-color: transparent;

        &::before {
          @include bg-partial($veeam-color-body, 'left');
        }
      }

      background-color: $veeam-color-body;
    }
  
    &.bg-partial--white {
      @media (min-width: 1680px) {
        background-color: transparent;
      }
    
      background-color: $veeam-color-control-bg;
    }


    &.bg-partial--green {
      @include media-breakpoint-up(xl){
        background-color: transparent;
        &::after {
          content: '';
          display: block;
          background-color: transparent;
          background-image: url("//img.veeam.com/careers/global/page_di/banner_left.png");
          background-size: cover;
          background-repeat: no-repeat;
          overflow: hidden;
          position: absolute;
          left: -190px;
          top: 0;
          height: 100%;
          width: 740px;
        }


        &::before {
          @include bg-partial($veeam-color-green, 'right');
        }
      }

      @media (min-width: 1600px) {
        &::after {
          left: 0px;
        }
      }

      &.bg-partial--pattern {
        @include media-breakpoint-up(md) {
          background-image: url("//img.veeam.com/careers/global/page_di/banner_right_transparent.svg");
          background-repeat: no-repeat;
        }

        @media (min-width: 1440px) {
          &::before {
            content: '';
            display: block;
            background-image: url("//img.veeam.com/careers/global/page_di/banner_right_transparent.svg");
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            background-position-x: 102%;
            background-position-y: top;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.13);
          }
        }
      }


      background-color: $veeam-color-green;
      color: $veeam-color-sapphire-dark;
      position: relative;
    }

    &.bg-partial--dark {
      @media (min-width: 1680px) {
        background-color: transparent;

        &::before {
          @include bg-partial($veeam-color-footer-bg, 'right');
        }

      }

      &.bg-partial--pattern {
        @media (min-width: 1680px) {
          &::before {
            background-position-x: 102%;
            background-position-y: bottom;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.13);
          }
        }

        @media (max-width: 1680px) {
          &::before {
            right: -50px;
            width: 163px;
            height: 163px;
          }
        }

        &::before {
          content: '';
          display: block;
          background-image: url("//img.veeam.com/careers/global/pattersn-opportinities/banner-dots.png");
          background-repeat: no-repeat;
          position: absolute;
          bottom: 0;
          right: -120px;
        }
      }

      background-color: $veeam-color-footer-bg;
      color: $veeam-color-control-bg;
      overflow: hidden;
    }
  }

  &-transparent {
    @include media-breakpoint-up(lg) {
      .header {
        border-bottom-color: transparent;
      }

      .nav-link {
        color: $veeam-color-control-bg !important;
      }


      .dropdown-toggle {
        &[aria-expanded="false"]::after {
          @include triangle-outlined(relative, 'down', $veeam-color-main, 3px);
          width: $icon-width;
          height: $icon-width;
          bottom: 4px;
          right: -5px;
        }

        &[aria-expanded="true"]::after {
          @include triangle-outlined(relative, 'up', $veeam-color-main, 3px);
          width: $icon-width;
          height: $icon-width;
          bottom: 0;
          right: -5px;
        }
      }
    }

    .dropdown-search {
      .nav-link::after {
        background: url(//img.veeam.com/careers/icons/icon_search_white.png);
      }
    }

    .bg-fade {
      background-color:  $veeam-color-footer-bg;
    }

  }

  &-with-video {
    @include media-breakpoint-up(xl) {
      height: 440px;
    }

    @media (min-width: 1440px) {
      height: $video-height;
    }

    @media (min-width: 1580px) {
      height: 570px;
    }

    @media (min-width: 1680px) {
      height: 620px;
    }

    @media (min-width: 1920px) {
      height: 700px;
    }

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &.bg-with-video--relative {
      @include media-breakpoint-up(xl) {
        width: $video-width;
        right: -100px;
      }

      @include media-breakpoint-up(md) {
        height: $video-height-lg;
      }

      height: $video-height-sm;

      video {
        display: block;
      }
    }

    video {
      @include media-breakpoint-up(xl) {
        display: block;
        min-width: 100%;
      }

      display: none;
    }
  }

  &-pattern-div-bnr-right {
    position: absolute;
    right: 0;
    top: 0;
  }



  &-pattern {
    &_empl_bnr_left,
    &_empl_bnr_right {
      display: none;

      @include media-breakpoint-up(md) {
        position: absolute;
        height: 100%;
        top: 0;
      }
    }
  }

  &-pattern_empl_bnr_left {
    @include media-breakpoint-up(xl) {
      display: block;
      left: -140px;
    }
  }

  &-pattern_empl_bnr_right {
    @include media-breakpoint-up(md) {
      display: block;
      right: -65px;
    }
  }

  &-banner-button {
    background-image: url("//img.veeam.com/careers/global/page_di/banners/banner_all.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}









