.internship {
  white-space: pre-wrap;
}

.launch {
  border-radius: 20px;
  background: linear-gradient(261.84deg, #CCCCCC 8.09%, #F1F1F1 50.66%);

  &-text {
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
  }

  &-wrapper {
    height: 500px;


    &-image {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      background: url(//img.veeam.com/careers/global/students-graduates/header-group.png) no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 90%;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: 768px) {
        border-radius: 20px;
      }
    }
  }
}

.student {
  &-name {
    color: #004550;
    font-weight: 700;
  }
  &-university {
    color:  #939598;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.question {
  &:hover {
    color: #2857FF
  }
}

.thumbnail {
  position: relative;
  border: none;
  border-radius: 20px;
  padding: 0;
  margin: 0;

  img {
    border-radius: 20px;
  }

  &-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.video-player {
  height:100vh;
  width:100vw;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 100;
}