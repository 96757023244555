$card-values-width-md: 36rem;
$card-values-width-sm: 26rem;
$max-card-width: 39rem;
$card-img-height: 11.6rem;
$card-img-width: 19.6rem;

.card {
  background: transparent;
  flex: 1;
  margin-bottom: 0;
  background: $veeam-color-control-bg;
  overflow: hidden;
  z-index: $card-z-index;

  &:not(.card-accordion):not(.card-values):not(.w-100):not(.card-homepage):not(.card-logos) {
    @include media-breakpoint-up(xl) {
      /*max-width: $max-card-width;*/
    }
  }

  &.w-auto {
    max-width: 100%
  }


  .btn-link-cta {
    padding: 0;
  }

  .card-header {
    align-items: flex-start;

    span + span {
      margin-left: $spacer;
    }
  }

  & + .card {
    margin-top: $card-spacer-y;
  }

  &.card--shadowed {
    border: 0;
    box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);
    position: static;
  }

  &.card-table {
    display: flex;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      flex-flow: row;
      min-width: 45%;

      .card-header {
        min-width: 20rem
      }
    }
  }

  &.card-logos {
    @include media-breakpoint-up(md) {
      min-width: 200px;
      max-width: 30%;

      .card-body {
        padding: 0;
      }
    }

    min-width: 50%;

    .card-body {
      padding: 0 $card-spacer-x 0 0;
    }
  }


  &.card-cta {
    border: 0;

    .card-body {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;

        .btn {
          margin-bottom: 0 !important;
        }
      }

      .btn {
        margin-bottom: $card-spacer-y;
      }
    }
  }

  &.card-banner {
    .card-header {
      padding-bottom: 0;
    }

    &.card-banner-main {
      min-height: 100%;

      &.card-banner-with-pattern {
        @include media-breakpoint-up(md) {
          background: url('//img.veeam.com/careers/icons/banner_pattern.png');
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
    }
  }


  &.card-banner__learn {
    @include media-breakpoint-up(md) {
      min-height: 190px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 320px;
    }

    position: relative;
    background: url("//img.veeam.com/careers/global/page_di/banners/banner_all.png");
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 0;
  }

  &.card-banner__apply {
    position: relative;
    background: url("//img.veeam.com/careers/global/apply/hand_2.png");
    background-repeat: no-repeat;
    background-position: bottom 40px right -10px;
    min-height: 600px;

    .img {
      position: relative;

      &.img__top {
        content: '';
        position: absolute;
        top: 0;
        right: -80px;
      }

      &.img__bottom {
        content: '';
        bottom: 0;
        position: absolute;
        right: -80px;
      }
    }

    .card-body {
      margin-top: 140px;
    }
  }

  &.card-banner__new {
    position: static;
    box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);
    border: 0;

    .card-header {
      position: relative;
    }
  }

  &-sm {
    @include media-breakpoint-up(md) {
      .card-header {
        align-items: flex-end;
      }
    }
    margin-bottom: 0;

    .card-header,
    .card-body,
    .card-footer {
      padding: $card-spacer-y - 1 $card-spacer-x - 1;
    }

    & + .card-sm {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }

  &-header-fixed {
    height: $spacer * 5;
  }

  &-pattern-bg {
    background: url("//img.veeam.com/careers/banners/banners_ru/pattern_cta.png");
    background-position: top -10px right -30px;
    background-color: $veeam-color-control-bg;
  }

  &-scroll {
    @include media-breakpoint-down(md) {
      overflow-x: scroll;
      margin-bottom: -3rem;

      &::-moz-viewport-scroll {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }


    &:not(.card-scroll--timeline):not(.card-scroll--partners) {
      @include media-breakpoint-up(md) {
        .nav-item + .nav-item {
          margin-top: 2.8rem;
        }
      }
    }


    &.card-scroll--timeline {
      @include media-breakpoint-up(lg) {
        &.move-left {
          left: -30px;
          transition: left 2s ease 1s;
        }

        &.move-right {
          right: -30px;
          transition: right 1s;
        }
      }

      border: none;
      position: relative;

      .nav-link > span {
        font-size: $font-size-base;
      }
    }

    &.card-scroll--partners {
      border: none;

      .nav-item:first-child > .nav-link {
        padding-left: 0;
      }

      .nav-item + .nav-item {

        &::before {
          content: '';
          display: block;
          background-color: $veeam-color-placeholder-pressed;
          width: 3px;
          height: $h3-font-size;
          position: absolute;
          transform: rotate(10deg);
          top: 8px;
        }

      }
    }
  }

  &.card-basis-sm {
    @include media-breakpoint-up(md) {
      flex-basis: 20%;
      max-width: 20%;
    }

    flex-basis: auto;
  }

  &.card-basis-lg {
    @include media-breakpoint-up(md) {
      flex-basis: 45%;
    }

    flex-basis: auto;
  }

  &.card-no-spacer-x {
    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-with-caption {
    @include media-breakpoint-up(md) {
      min-height: auto;
      max-width: 80%;

      .card-header {
        align-items: flex-start;
        flex: 1 0 8rem
      }
    }

    height: 100%;
    min-height: 46rem;
  }

  &.card-collapse {
    .btn-link {
      position: relative;
      padding-left: $spacer * 1;
      font-weight: bold;

      &[aria-expanded="true"]::after {
        content: '';
        display: block;
        background: url(//img.veeam.com/careers/landing/img/icons/accordion-opened.png);
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 0;
        left: -30px;
      }

      &[aria-expanded="false"]::before {
        content: '';
        display: block;
        background: url(//img.veeam.com/careers/landing/img/icons/accordion-closed.png);
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 0;
        left: -30px;
      }
    }

    .card-body {
      padding-left: $spacer * 4;
    }
  }

  &.card-double {
    @include media-breakpoint-up(md) {
      flex-flow: row;
      min-width: 63%;
    }

    @include media-breakpoint-up(xl) {
      flex-grow: 2;
      min-width: 64%;
    }

    .card {
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
      border: 0;

      &-footer {
        justify-content: flex-end;
      }
    }

    .card-image {
      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-left-radius: $card-border-radius;
      }

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 0;


      img {
        @media(max-width: 400px) {
          height: 300px;
        }

        @media(max-width: 768px) {
          height: 360px;
        }

        min-height: 100%;
      }

      & + .card {
        @include media-breakpoint-up(xl) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        margin-left: -1px;
      }
    }

    .card-image,
    .card-banner {
      @include media-breakpoint-up(xl) {
        max-height: 100%;
        min-height: 100%;
      }

      .card-footer {
        justify-content: flex-end !important;
      }
    }
  }

  &-columns {
    column-count: 5;
    margin-left: $card-columns-spacer;
    margin-right: $card-columns-spacer;
    column-gap: $card-columns-gap;

    .card {
      margin-bottom: $card-columns-gap;
    }
  }

  &-values {
    @include media-breakpoint-up(md) {
      min-width: $card-values-width-md;
      max-width: $card-values-width-md;
    }

    min-width: $card-values-width-sm;
    max-width: $card-values-width-sm;
    border: 0;
  }

  &-focus {
    @include media-breakpoint-up(md) {
      min-width: 40%;
    }

    /*.card-header > img {
      width: $card-img-width;
    }*/
  }

  &-md-45 {
    @include media-breakpoint-up(md) {
      min-width: 45%;
    }
  }

  &.card-accordion {
    border: 0;
    border-radius: 0;
    border-bottom: $border-width solid $veeam-color-placeholder;

    .card-header,
    .card-body {
      padding-left: 0;
      padding-right: $card-spacer-x * 2;
    }

    .card-header {
      padding-bottom: $card-spacer-y / 1.5;

      &--closed {
        .accordion-title {
          color: $veeam-color-font;
        }

        &::after {
          @include triangle-outlined(absolute, 'down', $veeam-color-main, 3px);
          right: $card-spacer-x / 2;
          top: $card-spacer-y + $icon-height;
        }
      }

      &--opened {
        .accordion-title {
          color: $veeam-color-main;
        }

        &::after {
          @include triangle-outlined(absolute, 'up', $veeam-color-main, 3px);
          right: $card-spacer-x / 2;
          top: $card-spacer-y + $icon-height;
        }
      }
    }
  }
}


a.card-no-hover {
  color: $veeam-color-control-bg;
}

a.card-double.card-no-hover {
  .display-3 {
    color: $veeam-color-font;
  }
}

a.card-no-hover:hover,
a.card-body:hover{
  text-decoration: none !important;
}


a.card-clickable {
  &:hover {
    text-decoration: none;
  }

  &.card-banner:hover {
    color: $veeam-color-control-bg;
  }

  &.card-news,
  &.card-stories {
    color: $veeam-color-font;

    &:hover {
      color: $veeam-color-font;
    }
  }

}


.card-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: $border-width * 4 solid $veeam-color-font;


  span.indicator.indicator-left {
    @include media-breakpoint-up(xl) {
      cursor: pointer;
      padding: 0 10px;
      background-color: $veeam-color-body;
      position: absolute;
      left: 0;
      top: 7px;
      z-index: $zindex-modal;
      display: block;
      align-items: center;
      width: 20px;
      height: 20px;

      &::after {
        @include triangle-outlined(absolute, 'left', $veeam-color-main, 3px);
        left: 5px;
        top: 7px
      }
    }

    display: none;
  }

  span.indicator.indicator-right {
    @include media-breakpoint-up(xl) {
      cursor: pointer;
      padding: 0 10px;
      background-color: $veeam-color-body;
      position: absolute;
      right: 0;
      top: 7px;
      z-index: $zindex-modal;
      display: block;
      align-items: center;
      width: 20px;
      height: 20px;

      &::after {
        @include triangle-outlined(absolute, 'right', $veeam-color-main, 3px);
        right: 4px;
        top: 7px
      }
    }

    display: none;
  }
}


.card-homepage-wrapper {
  .card + .card {
    margin-top: 0;
  }
}

