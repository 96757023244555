$display-spacer: 2rem;

.display-number {
  @include media-breakpoint-up(lg) {
    font-size: $display-number-size-lg;
  }

  font-size: $display-number-size-sm;
  line-height: 1.4;
}
@include headings-sm('h1');
@include headings-sm('h2');
@include headings-sm('h3');
@include headings-sm('h4');
@include headings-sm('display-1');
@include headings-sm('display-2');
@include headings-sm('display-3');
@include headings-sm('display-4');

@include media-breakpoint-up(lg) {
  @include headings-md('h1');
  @include headings-md('h2');
  @include headings-md('h3');
  @include headings-md('h4');
  @include headings-md('display-1');
  @include headings-md('display-2');
  @include headings-md('display-3');
  @include headings-md('display-4');
  @include headings-md('display-number');
}

@include media-breakpoint-up(xl) {
  @include headers-spacing('h1');
  @include headers-spacing('h2');
  @include headers-spacing('h3');
  @include headers-spacing('h4');
  @include headers-spacing('display-1');
  @include headers-spacing('display-2');
  @include headers-spacing('display-3');
  @include headers-spacing('display-4');
}


/*.display-1,
.display-2,
.display-3,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: $font-family-guardian-header;
  font-weight: 600;
}*/

h5 {
  font-weight: normal;
}

.display-1,
.display-2,
.display-3 {
  margin-top: 0;
  /*font-weight: $font-weight-bold;*/
}
