.img-logo {
  @include media-breakpoint-up(lg) {
    width: $img-logo-width;
  }

  margin-right: $nav-link-padding-x + 1rem;
  width: $img-logo-width / 2;
}

.img-banner--absolute {
  @include media-breakpoint-down(md) {
    max-width: 364px;
    left: -156px;
    position: absolute;
  }
}

.img-banner--absolute-bottom {

  @include media-breakpoint-up(md) {
    min-width: 300px;
    bottom: -90px;
    left: 20px;
    position: absolute;
  }

  @include media-breakpoint-up(lg) {
    min-width: 410px;
    top: 10px;
  }

  @include media-breakpoint-up(xl) {
    min-width: 550px;
    top: -90px;
    left: -20px;
  }

  @media (min-width: 1500px) {
    min-width: 680px;
    top: -100px;
    left: -180px;
  }

  bottom: -100px;
  position: relative;
  width: 280px;
}


.img-banner--cloud-with-star {
  @include media-breakpoint-up(md) {
    top: 15px;
    width: 264px;
  }

  @media (min-width: 540px) {
    top: -18px;
  }

  @include media-breakpoint-up(xl) {
    top: -($menu-nav-height / 2);
    width: 264px;
  }

  @media (min-width: 1366px) {
    top: -$menu-nav-height;
    width: 508px;
    right: 100px;
  }

  display: inline-block;
  position: absolute;
  z-index: 100;
  right: $grid-gutter-width / 2;
  top: 0;
  width: 50%;
}

.img-banner--cloud {
  @include media-breakpoint-up(md) {
    display: inline-block;
    top: -($menu-nav-height);
    width: 174px;
  }

  @include media-breakpoint-up(xl) {
    width: 200px;
  }

  @media (min-width: 1366px) {
    width: 336px;
  }

  display: none;
  position: absolute;
  z-index: 100;
  left: 33%;
}


.img-pattern {
  @media (min-width: 1500px) {
    display: inline-block;
    position: absolute;
    z-index: 100;
  }

  display: none;

  /*&.img-pattern--cloud-with-star {
    left: 58%;
    top: -$menu-nav-height;
  }

  &.img-pattern--cloud {
    left: 33%;
    top: -($menu-nav-height * 2);
  }*/

  &.img-pattern-right-top {
    @media (max-width: 1600px) {
      max-width: 45%;
    }

    right: 0;
    top: -22px;
  }

  &.img-pattern-right-bottom {
    @media (max-width: 1670px) {
      right: 0;
    }
    right: 0;
    bottom: 50%
  }

  &.img-pattern-left-top {
    @media (max-width: 1600px) {
      max-width: 45%;
    }

    top: $card-spacer-y * 2;
    left: -130px;
  }

  &.img-pattern-left-middle {
    left: 0;
    top: 35%;
  }

  &.img-pattern-left-side {
    top: -415px;
  }

  &.img-pattern-left-bottom {
    left: -120px;
    bottom: 0;
  }

  &.img-pattern-social {
    right: 0;
    top: -331px;
  }
}

.pattern-wrapper {
  position: relative;
}

.icon-flag {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -$icon-sprites-width * 1.5;
    display: inline-flex;
    width: $icon-sprites-width;
    height: $icon-sprites-height;
  }
}

.flag-ru::after {
  background: url("//img.veeam.com/careers/img/img_ru/flag_ru.png");
}

.flag-cz::after {
  background: url("//img.veeam.com/careers/img/img_ru/flag_cz.png");
}

.img-steps {
  position: absolute;
  right: -26px;
}

.img-legend {
  display: none;
  width: 10rem;
  height: 5rem;
  background: $veeam-color-control-bg;

  &.show {
    display: block;
  }
}

.img-gender-pattern {
  @media (min-width: 1680px) {
    display: inline-block;
    position: absolute;
    z-index: 1;
  }
  
  display: none;
  
  &.img-gender-pattern-left {
    @media (max-width: 1690px) {
      max-width: 45%;
    }
  }
  &.img-gender-pattern-right {
    @media (max-width: 1690px) {
      max-width: 45%;
    }
    right: 0;
    top: 0
  }
  &.img-gender-pattern-bottom {
    @media (max-width: 1690px) {
      max-width: 45%;
    }
    top: 30%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

