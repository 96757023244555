$carousel-icon-size: 56px;
$carousel-nav-icon-size: 7.5px;


.carousel {

  &-inner {
    @media (max-width: 320px) {
      min-height: 50rem;
    }

    z-index: 110;
  }

  &-title {
    min-width: 50rem;
    max-width: 50rem;
    text-align: left;

    .carousel-indicators {
      display: none;
    }
  }


  &-control-next,
  &-control-prev {
    @media (min-width: 1440px) {
      display: flex;
      top: -120px;
      width: 10%;
      z-index: $zindex-tooltip;

      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: $carousel-icon-size;
        width: $carousel-icon-size;
      }
    }

    display: none;
  }

  &-control-next {
    right: -80px;

    .carousel-control-next-icon {
      border: $border-width * 4 solid $veeam-color-placeholder-pressed;

      &::after {
        @include triangle-outlined(relative, 'right', $veeam-color-placeholder-pressed, 3px);
        width: $carousel-nav-icon-size;
      }
    }

    &:hover,
    &:focus {
      .carousel-control-next-icon {
        border: $border-width * 4 solid $veeam-color-light-green;

        &::after {
          @include triangle-outlined(relative, 'right', $veeam-color-light-green, 3px);
          width: $carousel-nav-icon-size;
        }
      }
    }
  }

  &-control-prev {
    left: -80px;

    .carousel-control-prev-icon {
      border: $border-width*4 solid $veeam-color-placeholder-pressed;

      &::after {
        @include triangle-outlined(relative, 'left', $veeam-color-placeholder-pressed, 3px);
        width: $carousel-nav-icon-size;
      }
    }

    &:hover,
    &:focus {
      .carousel-control-prev-icon {
        border: $border-width*4 solid $veeam-color-light-green;

        &::after {
          @include triangle-outlined(relative, 'left', $veeam-color-light-green, 3px);
          width: $carousel-nav-icon-size;
        }
      }
    }

  }

  &-indicators {
    bottom: -5rem;

    li {
      width: 8.8rem;
      height: 0.6rem;
      background-color: $veeam-color-disabled;

      &.active {
        background-color: $veeam-color-light-green
      }
    }
  }

  /*&-md {
    .carousel-control-next {
      right: -40px;
    }

    .carousel-control-prev {
      left: -40px;
    }
  }*/

  .card + .card {
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }

    /*margin-left: $spacer * 2;*/
  }

  &.carousel-xl {
    .carousel-inner {
      min-height: 73rem;
    }
  }
}

.card-homepage--slider {
  .carousel-control-prev,
  .carousel-control-next {

   @include media-breakpoint-up(md) {
      display: flex;
      top: -120px;
      width: 10%;
      z-index: $zindex-tooltip;

      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: $carousel-icon-size;
        width: $carousel-icon-size;
      }
    }

    display: none;
  }


  .carousel-control-prev {
    left: 5px;
    top: 0;
  }

  .carousel-control-next {
    right: 5px;
    top: 0;
  }

  .carousel-indicators {
    @media (max-width: 350px) {
      bottom: 9rem;
    }

    bottom: -8rem;
  }
}
