$card-slider-sm-height: 78rem;
$card-slider-md-height: 85rem;
$card-slider-min-width: 26rem;
$card-slider-max-card: 49rem;


.card-deck {
  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
  }

  flex-flow: column;
  display: flex;


  &-slider .card {
    @include media-breakpoint-up(md) {
      min-height: $card-slider-md-height;

    }

    @media (min-width: 1440px) {
    max-width: $card-slider-max-card;
    }

    min-width: $card-slider-min-width;
    min-height: $card-slider-sm-height;

    img {
      min-width: 100%;
    }
  }

  .card {
    margin-bottom: 3rem;

    & + .card {
      margin-top: 0;
    }

  }

  &.card-deck__card-deck-shadowed {

    .card {
      box-shadow: 0 4px 8px rgba(35, 35, 35, 0.04);
    }
  }
}

