.back-to-top {
  @media(min-width: 1440px) {
    display: none;
    position: fixed;
    bottom: 120px;
    right: 30px;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
    z-index: 999;
    justify-content: center;
    align-items: center;

    &.show {
      display:flex;
    }

    img {
      transform: rotate(-180deg);
    }
  }

  display: none;
}
