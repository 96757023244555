$veeam-color-main: #00b336;
$veeam-color-light-green: #93ea20;
$veeam-color-light-green-hover: #00dc42;
$veeam-color-light-green-banner: #00e296;
$veeam-color-light-green-badge: #00b492;
$veeam-color-light-green-pressed: #008a2a;
$veeam-color-green: #00b492;
$veeam-color-highlight: #01a891;
$veeam-color-sapphire-light: #4c7d84;
$veeam-color-sapphire: #005f4b;
$veeam-color-sapphire-dark: #002930;

$veeam-color-control-bg: #fff;
$veeam-color-body: #f1f1f1;
$veeam-color-placeholder: #e1e1e1;
$veeam-color-disabled: #cdcdcd;
$veeam-color-placeholder-pressed: #999999;

$veeam-color-black: #000;
$veeam-color-footer-bg: #004550;
$veeam-color-font: #232323;
$veeam-color-danger: #ba0200;
$veeam-color-warning: #f7941d;
$veeam-color-blue: #2957ff
