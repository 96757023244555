$veeam-color-border-focus: $veeam-color-sapphire;
$btn-play-width: 70px;
$btn-min-width: 150px;

.btn:not(.btn-link):not(.btn-tag):not(.dropdown-toggle):not(.btn-sm):not(.btn-play):not(.btn-close) {
  @include media-breakpoint-down(md) {
    min-width: 100%;
  }

  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;
  min-width: $btn-min-width;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-outline-font:hover {
    background-color: $veeam-color-placeholder;
    border-color: $veeam-color-placeholder;
}

.btn-outline-font:not(:disabled):not(.disabled):active,
.btn-outline-font:not(:disabled):not(.disabled).active {
  background-color: $veeam-color-placeholder;
  border-color: $veeam-color-placeholder;
}


.btn-outline-warning:focus,
.btn-outline-warning {
  color: $veeam-color-warning;
}

.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: $veeam-color-control-bg;
}

.btn-sm {
  border-width: $border-width;

  &__spacer {
    @include media-breakpoint-up(md) {
      margin-bottom: 1.2rem;
    }

    margin-left: 1.2rem;
    white-space: nowrap;
  }
}

.btn-apply {
  padding: $btn-padding-y - 0.2 $btn-padding-x;
}

.btn-play {
  display: flex;
  align-items: center;
  position: absolute;
  width: $btn-play-width;
  max-width: $btn-play-width;
  height: $btn-play-width;
  border-radius: 50%;
  background-color: $veeam-color-light-green !important;
  z-index: 100;
  left: calc(50% - #{$btn-play-width} / 2);

  &::after {
    @include triangle-filled(10px, relative, 'right', $veeam-color-control-bg);
    left: 40%;
  }
}

.btn-cta {
  @include media-breakpoint-up(xl) {
    min-width: $spacer * 28;
  }

  flex: 1;
  width: 100%;
}

.btn-secondary {
  color: $veeam-color-border-focus;
}

.btn.btn-link {
  border: 0;
  color: $veeam-color-main;
  text-decoration: none;
  padding: 0;
  position: relative;
  text-align: left;
  vertical-align: baseline;

  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }

  &.btn-link--fake {
    display: block;
    color: $veeam-color-font;

    &:hover,
    &:focus {
      color: $veeam-color-main;
    }
  }

  &.btn-link--shadowed {
    box-shadow: 0 -4px 8px rgba(35,35,35,.04);
    border-radius: 0;
  }


  &.btn-link__cta:hover,
  &.btn-link__cta {
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    position: relative;
    color: $veeam-color-main;

    &::after {
      @include triangle-outlined(relative, 'right', $veeam-color-main, 3px);
      left: 4px;
    }

    &.btn-link__cta-secondary {
      color: $veeam-color-light-green;

      &::after {
        @include triangle-outlined(relative, 'right', $veeam-color-light-green, 3px);
      }
    }
  }

  &:hover {
    background: none;
  }
}

.btn.btn-search {
  position: relative;
  &::after {
    background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -146px -54px;
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    right: $icon-sprites-width;
  }
}

.btn-group--switcher {
  min-width: 100%;

  .btn {
    min-width: auto !important;
  }
}

.btn-close {
  position: relative;
  /*right: 0;
  top: 10px;*/
  width: 13px;
  height: 13px;

  &:before, &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: $veeam-color-main;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

}
