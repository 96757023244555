.nav {
  &-tabs {
    border-bottom: 0;
  }

  &-item {
    text-align: right;
  }

  &-item.nav-item--z-index {
    z-index: $card-z-index;
  }

  &-link {
    display: inline-flex;
    position: relative;

    &[role] {
      @include media-breakpoint-down(sm) {
        /*padding-left: 0;*/
        margin-bottom: $spacer * 2;
        flex-wrap: nowrap;
      }

      flex-wrap: wrap;
    }

    & > span {
      @include media-breakpoint-up(md) {
        white-space: normal;
        font-size: $h3-font-size;
      }

      display: inline-flex;
      color: $veeam-color-placeholder-pressed;
      font-size: $h5-font-size;
      line-height: 1.1;
      position: relative;
      white-space: nowrap;


      &:not(.nav-link--timeline)::after {
        bottom: 0;
        content: '';
        display: block;
        height: 7px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }

    &.active > span {
      color: $veeam-color-font;


    }

    &:not(.nav-link--timeline):hover > span::after {
      background-color: $veeam-color-light-green-hover;
    }

    &:not(.nav-link--timeline).active > span::after,
    &:not(.nav-link--timeline):focus > span::after {
      background-color: $veeam-color-main;
    }

    &.nav-link--timeline {
      padding-left: 0;
      padding-bottom: $spacer * 0.6;

      &.active > span,
      &.nav-link--timeline:focus > span,
      &.nav-link--timeline:hover > span {
        color: $veeam-color-main;
      }
    }
  }

  &-map {
    .nav-link {
      color: white;

      &.active {
        color: $veeam-color-light-green;
      }
    }
  }

  &.nav-tabs-tags {
    border-bottom: 0;

    .nav-item {
      border-radius: 18px;
      background-color: transparent;
      border: $border-width solid $veeam-color-font;
      color: $veeam-color-font;
      padding-top: $input-btn-padding-y / 2;
      padding-bottom: $input-btn-padding-y / 2;
      flex-flow: row;
      white-space: nowrap;
      margin-bottom: 0.2rem;

      &.active {
        background-color: $veeam-color-disabled;
        color: $veeam-color-control-bg;
        border: $border-width solid $veeam-color-disabled;
      }

      & + .nav-item {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          margin-left: $spacer *1.2;
          margin-top: 0;
        }
      }
    }
  }
}
