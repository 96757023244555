.page-menu {
  @media (min-width: 1440px) {
    display: block;
  }

  display: none;
  position: fixed;
  bottom: 200px;
  right: 30px;
  justify-content: center;
  align-items: center;
  text-align: right;
  font-size: 1.4rem;
  z-index: 1080;

  ul {
    list-style: none;
  }

  li {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    a {
      color: $veeam-color-disabled;

      &:hover {
        text-decoration: none;
        color: darken($veeam-color-main, .7)
      }
    }

    &.is-active {
      > a {
      color: $veeam-color-main;
      }

      &::after {
        background: $veeam-color-main;
      }
    }



    &::after {
      content: '';
      display: block;
      background: $veeam-color-disabled;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 7px;
    }
  }

/*  &::after {
    content: '';
    display: block;
    position: absolute;
    background: url("//img.veeam.com/careers/global/icons/scroll.svg") no-repeat;
    width: 30px;
    height: 80px;
    bottom: -121px;
    left: 70%;
  }*/

  &-wrapper {
    position: relative;
  }

  .is-active {
    color: red;
  }
}


