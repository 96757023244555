$card-spacer-y: 2rem;

.card {
  .card-header,
  .card-footer {
    display: flex;
    border: 0;
  }


  .card-footer {
    justify-content: space-between;

    .location {
      @include media-breakpoint-up(xl) {
        span + span {
            margin-left: $spacer;
        }
      }
      display: flex;
    }
  }
}
