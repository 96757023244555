$badge-padding-x: 28px;
$badge-padding-y: 10px;


.badge {
  @include media-breakpoint-up(xl) {
    max-width: 186px;
  }

  background: $veeam-color-light-green-badge;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  color: $veeam-color-control-bg;
  min-height: 84px;
  max-width: 160px;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $font-size-base;
  top: $card-spacer-y;
  text-align: left;
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;

  &--regular {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    white-space: normal;
  }

  &--sm {
    font-size: $font-size-sm;
    white-space: normal;
    font-weight: $font-weight-base;
  }
}
