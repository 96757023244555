.arrow {

  @media (max-width: 1365px) {
    &.arrow--img {
      order: -1;
    }

    &.arrow--fourth {
      order: 3;
    }

    &.arrow--fifth {
      order: 4;
    }
  }

  @media (min-width: 1366px) {
    &.arrow--first::before {
      @include arrow-graphics('//img.veeam.com/careers/global/icons/arrow_one.png');
      width: 320px;
      height: 370px;
      left: 140px;
      top: -10px;
    }

    &.arrow--second::before {
      @include arrow-graphics('//img.veeam.com/careers/global/icons/arrow_two.png');
      width: 320px;
      height: 370px;
      left: 226px;
      bottom: -75px;
    }

    &.arrow--third::before {
      @include arrow-graphics('//img.veeam.com/careers/global/icons/arrow_three.png');
      width: 320px;
      height: 400px;
      left: 127px;
      top: 44px;
    }

    &.arrow--fourth::after {
      @include arrow-graphics('//img.veeam.com/careers/global/icons/arrow_four.png');
      width: 320px;
      height: 400px;
      right: 435px;
      top: 48px;
    }

    &.arrow--fourth {
      order: 4;
    }

    &.arrow--fifth {
      order: 3;
    }
  }


  @include media-breakpoint-up(lg) {
    max-width: 50%;
  }

  position: relative;
  width: 100%;
}
