.contacts {


  &__map,
  &__phone {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      width: $icon-sprites-width;
      height: $icon-sprites-height;
      top: -1px;
      left: - $icon-sprites-width * 1.5;
    }
  }

  &__map::before {
    background-image: url("//img.veeam.com/careers/czech/icon_location.png");
  }

  &__phone::before {
    background-image: url("//img.veeam.com/careers/czech/icon_phone.png");
  }
}
