.chart-legend {
  text-align: left;

  .text-legend {
    display: inline-block;
    width: $icon-sprites-width;
    height: $icon-sprites-height;
    border-radius: 50%;

    &.text-legend-green {
      background-color: $veeam-color-green;
    }

    &.text-legend-secondary {
      background-color: $veeam-color-light-green;
    }
  }
}
