.container-footer {
  @include media-breakpoint-up(md) {
    width: 100%;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}

footer.footer {
  @include media-breakpoint-up(md) {
    padding: $card-spacer-y - 1 !important;
  }

  align-items: center;
  background-color: $veeam-color-control-bg !important;
  position: relative;
  padding: $card-spacer-y 0;
  border-top: none !important;

  & .footer__link {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    &:not(:first-child)::before {
      content: '';
      background-color: $veeam-color-font;
      display: inline-block;
      height: $font-size-base;
      position: absolute;
      top: 3px;
      left: -10px;
      width: 2px;
    }
  }
}
